





















































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import draggable from "vuedraggable";
import { Project } from "@/data/models/Projects";
import { Guid } from "guid-typescript";
import { OpenCall } from "@/data/models/OpenCall";
import DatePicker from "@/components/DatePicker.vue";
import FileInput from "@/components/FileInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
    FileInput,
    EntitySelect,
    draggable
  }
})
export default class OpenCallForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<OpenCall>;

  @Prop()
  public crud!: ICrudClient<OpenCall>;

  @Prop()
  public projectsProvider!: DataProvider<Project>;

  private item: any = null;

  private programmeODataArgs = {
    columnFilters: [
      {
        column: "Type",
        value: "'Regranting'",
        op: "Equals"
      }
    ],
    top: 100
  };
  private calculationMethods = [
    "Quality",
    "QualityPrice",
    "EuropeanQualityPrice"
  ];

  async mounted() {
    if (!this.id || this.id == "new") {
      this.item = {
        id: Guid.create().toString(),
        startDate: null,
        endDate: null,
        comments: ""
      };
    } else {
      try {
        const openCall = await this.provider.fetchItemAsync(this.id);
        if (!openCall) {
          this.item = {
            id: Guid.create().toString(),
            startDate: null,
            endDate: null,
            comments: ""
          };
        }
      } catch {
        this.item = {
          id: Guid.create().toString(),
          startDate: null,
          endDate: null,
          comments: ""
        };
      }
    }
  }
}
