import { render, staticRenderFns } from "./OpenCallForm.vue?vue&type=template&id=128b9c7a&scoped=true&"
import script from "./OpenCallForm.vue?vue&type=script&lang=ts&"
export * from "./OpenCallForm.vue?vue&type=script&lang=ts&"
import style0 from "./OpenCallForm.vue?vue&type=style&index=0&id=128b9c7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128b9c7a",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QSeparator,QCardSection,QInput,QSelect,QBtn,QEditor,QExpansionItem,QItem});
